.view-mappings {
  &__carousel-wrapper {
    .view-mappings__nav-buttons-wrapper-props {
      bottom: -1.438rem;
      top: unset;
    }
    .view-mappings__nav-buttons-props {
      background-color: transparent;
      color: #6750a4;
      border-radius: 0;
      width: 0;
      padding: 0;
      margin: 0;
    }
    .view-mappings__indicator-icon-button-props {
      display: none;
      font-size: 1rem;

      &,
      &:disabled {
        color: #6750a4;
      }
    }
    .view-mappings__active-indicator-icon-button-props {
      display: inline-flex;
    }
    .view-mappings__indicator-container-props {
      background-color: #f4eff4;
      padding: 0.938rem;
      border-radius: 3.125rem;
      width: 37.5rem;
      margin: 3.5rem auto 0;
    }
  }

  &__slider-buttons {
    position: absolute;
    top: -0.875rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
  }

  &__slider-prev {
    left: 19.688rem;
  }

  &__slider-next {
    right: 19.688rem;
  }

  &__slide-image-placeholder {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__slide-image,
  &__slide-video {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}
