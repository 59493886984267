.pool-metadata {
  &__table-row:nth-child(even) {
    background-color: #f6edff;
  }
  &__table-title {
    position: relative;
    flex: 0 1 20%;
    padding: 5px 10px;
    &:after {
      content: "";
      background-color: #ccc;
      position: absolute;
      top: 15%;
      right: 0;
      width: 1px;
      height: 70%;
    }
  }
  &__table-content {
    flex: 0 1 80%;
    padding: 5px 10px;

    &--campaign {
      flex: 1;
      padding: 5px 10px;
    }
  }
  &__update {
    align-self: center;
    white-space: nowrap;
    margin-right: 10px !important;
  }
}