.kantar-login {
    position: relative;
    height: 100vh;

    &__box {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
    }

    &__paper {
        position: relative;
        width: 100%;
        height: 350px;
        text-align: center;
    }

    &__logo {
        width: 350px;
        margin-top: 50px;
    }

    &__button-container {
        position: absolute;
        width: 100%;
        bottom: 130px;
    }
}
