.update-creative {
  height: 100%;
  overflow: auto;

  &__carousel-wrapper {
    .update-creative__nav-buttons-wrapper-props {
      bottom: -1.438rem;
      top: unset;
    }
    .update-creative__nav-buttons-props {
      background-color: transparent;
      color: #6750A4;
      border-radius: 0;
      width: 0;
      padding: 0;
      margin: 0;
    }
    .update-creative__indicator-icon-button-props {
      display: none;
      font-size: 1rem;

      &,
      &:disabled {
        color: #6750a4;
      }
    }
    .update-creative__active-indicator-icon-button-props {
      display: inline-flex;
    }
    .update-creative__indicator-container-props {
      background-color: #F4EFF4;
      padding: 0.938rem;
      border-radius: 3.125rem;
      width: calc(100% - 60px);
      margin: 1.5rem auto 0;
    }
  }

  &__slider-buttons {
    position: absolute;
    top: -0.875rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
  }

  &__slider-prev {
    left: 40px;
  }

  &__slider-next {
    right: 40px;
  }

  &__slide-image-placeholder {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__slide-image,
  &__slide-video {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}

.creative-media-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;

  &__media-placeholder,
  &__media-image,
  &__media-video,
  &__media-iframe {
    width: 100%;
    object-fit: contain;
    min-height: 0;
  }

  &__media-iframe {
    border: none;
    height: 100vh;
  }

  &__media-description {
    height: 200px;
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
    overflow: auto;
  }
}