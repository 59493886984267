.pool-media-wrapper {
  display: flex;
  justify-content: center;
  &__media-placeholder,
  &__media-image,
  &__media-video,
  &__media-iframe {
    max-width: 70%;
    max-height: 350px;
  }
  &__media-iframe {
    width: 100%;
    height: 100vh;
    border: none;
  }
}