.mappings-filter {
  &__modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5rem;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
  }
}