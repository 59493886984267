.product-default {
  color: var(--mui-palette-action-disabled);
  font-weight: 500;
}

.data-table {
  table-layout: fixed;
  border-collapse: separate;

  &.MuiTable-root {
    border-spacing: 0 2px;
  }

  &__row {
    user-select: none;

    span.product {
      &-validated,
      &-manual-domain-mapped,
      &-auto-domain-mapped,
      &-manual,
      &-auto {
        color: var(--mui-palette-success-main);
        font-weight: 500;
      }
  
      &-rejected {
        color: var(--mui-palette-error-main);
        font-weight: 500;
      }
  
      &-not-mapped {
        color: #4a4458;
        font-weight: 500;
      }
  
      &-requested,
      &-created {
        color: #219fd6;
        font-weight: 500;
      }
    }

    td {
      &:first-child {
        border-top-left-radius: var(--mui-shape-borderRadius);
        border-bottom-left-radius: var(--mui-shape-borderRadius);
      }

      &:last-child {
        border-top-right-radius: var(--mui-shape-borderRadius);
        border-bottom-right-radius: var(--mui-shape-borderRadius);
      }
    }

    &--disabled {
      td {
        color: var(--mui-palette-action-disabled);
      }
    }

    &--locked {
      &.data-table__row.MuiTableRow-root.Mui-selected {
        background-color: transparent;
        outline: 1px solid var(--mui-palette-primary-main);
        outline-offset: -1px;
        border-radius: var(--mui-shape-borderRadius);
      }
    }

    &.MuiTableRow-root.Mui-selected {
      background-color: #e8def8;
      border-radius: var(--mui-shape-borderRadius);
    }
  }

  &__header-cell {
    position: relative;

    &--checkbox::after {
      display: none;
    }

    &:last-of-type::after {
      display: none;
    }

    &::after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 1px;
      height: 16px;
      background-color: #000;
    }
  }
}

.data-table-pagination {
  &__nav {
    display: inline-block;
  }

  .MuiToolbar-root {
    flex-wrap: wrap;
  }

  .goto-page__button,
  .MuiPaginationItem-previousNext {
    border: 1px solid #79747e;

    &:disabled {
      background: #e6e1e5;
    }
  }

  .MuiPaginationItem-root {
    &.Mui-selected {
      color: inherit;

      &,
      &:hover {
        background-color: #eaddff;
      }

      &.Mui-focusVisible {
        color: #fff;
      }
    }
  }

  .goto-page {
    &__field {
      width: 60px;
      margin: 0 8px;
      border-radius: 4px;

      label,
      input {
        font-size: 14px;
      }

      label {
        line-height: normal;
      }

      input {
        padding: 6px 8px;
      }
    }

    &__button {
      width: 32px;
      height: 32px;
    }
  }
}
