@use "../../../style/variables";

.header {
  &__menu {
    color: #474747;
  }

  &__logo {
    display: inline-block;
    flex-grow: 1;

    img {
      height: variables.$header-height;
      vertical-align: middle;
    }
  }
}
