.user-pool-thumbnails {
  &__wrapper {
    overflow-y: auto;
    height: calc(100vh - 135px);
  }
  &__image-wrapper {
    width: 100%;
    height: 150px;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    &.selected {
      background-color: #f6edff;
    }
  }
  &__video-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &__image, &__video, &__iframe {
    max-width: 95%;
    max-height: 95%;
  }
  &__iframe {
    width: 100%;
    height: 100vh;
    border: none;
    pointer-events: none;
  }
}