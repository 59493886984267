.mapping-filter {
  &__search-container {
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 2;
    &:hover {
      border-color: #000;
    }
    .MuiTextField-root {
      background: #fff;
      width: 100%;
    }
  }

  &__search-select {
    flex: 1;
    width: 100%;
    fieldset {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &::after {
      content: "";
      background: rgba(var(--mui-palette-common-onBackgroundChannel) / 0.23);
      position: absolute;
      top: 20%;
      right: 0;
      height: 60%;
      width: 1px;
    }
  }

  &__search-text {
    flex: 2;
    width: 100%;
    fieldset {
      border-right: 0;
      border-left: 0;
      border-radius: 0;
    }
  }

  &__search-button-wrapper {
    background: #fff;
    display: flex;
    align-items: center;
    flex: 0;
    height: 100%;
    border: 1px solid rgba(var(--mui-palette-common-onBackgroundChannel) / 0.23);
    border-left: 0;
    border-top-right-radius: var(--mui-shape-borderRadius);
    border-bottom-right-radius: var(--mui-shape-borderRadius);
    padding: 0 10px;
  }
}