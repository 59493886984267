.threshold {
  &__content {
    background-color: #FFFBFE;
    box-shadow: 0px 1px 3px 1px #00000026;
    border-radius: 8px;
  }
  &__modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 18.75rem;
    background-color: #fff;
    padding: 0.625rem;
    border-radius: 8px;
  }
}
