@use "../../../style/variables";

.burger-menu {
  &.MuiDrawer-root,
  .MuiDrawer-paper {
    width: variables.$burger-menu-width;
    top: variables.$header-height + 1;
  }

  .MuiDrawer-paper,
  .MuiListSubheader-root {
    background-color: #fff;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__section {
    &.MuiAccordion-root {
      background: none;
      box-shadow: none;
    }
  }

  &__title {
    min-height: 0 !important;

    .MuiAccordionSummary-content {
      margin: 0 !important;
    }

    &.selected {
      box-shadow: inset 4px 0px 0px var(--mui-palette-primary-light);
    }
  }

  &__element {
    &.MuiAccordionDetails-root {
      padding: 0;
    }
  }
}
